import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTickets(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/support', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSupportTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/support-types', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSingleTicket(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/support/${data.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMessages(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/support-messages', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addTicket(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/support', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addMessage(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/support-messages/', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
