var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-user-sidebar","visible":_vm.isAddNewTicketSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.resetTicketData,"change":function (val) { return _vm.$emit('update:is-add-new-ticket-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('Add support ticket'))+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"formTicket"},[_c('status-alert',{attrs:{"is-success":_vm.isSuccess,"error-data":_vm.errorData,"success-msg":_vm.$t('Tax and currency info updated')}}),_c('b-form',{staticClass:"p-2"},[_c('validation-provider',{attrs:{"name":_vm.$t('Ticket Type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Ticket Type'),"label-for":"title"}},[_c('v-select',{staticClass:"lg-input",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.ticketTypeOptions,"reduce":function (val) { return val.value; },"autocomplete":"chrome-off","clearable":false,"state":errors.length > 0 ? false:null,"input-id":"country_KDR"},model:{value:(_vm.ticketData.support_type),callback:function ($$v) {_vm.$set(_vm.ticketData, "support_type", $$v)},expression:"ticketData.support_type"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('Support Title'),"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Support Title'),"label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","autofocus":"","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.ticketData.title),callback:function ($$v) {_vm.$set(_vm.ticketData, "title", $$v)},expression:"ticketData.title"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('Message'),"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Message'),"label-for":"message"}},[_c('b-form-textarea',{attrs:{"id":"textarea-state","placeholder":"Enter ticket message","rows":"3","state":errors.length > 0 ? false:null},model:{value:(_vm.ticketData.message),callback:function ($$v) {_vm.$set(_vm.ticketData, "message", $$v)},expression:"ticketData.message"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"button"},on:{"click":_vm.saveData}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }