<template>

  <div>
    <new-ticket
      :is-add-new-ticket-sidebar-active.sync="isAddNewTicketSidebarActive"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('Entries') }}</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">

              <b-button

                variant="primary"
                @click="isAddNewTicketSidebarActive = true"
              >
                <span class="text-nowrap">{{ $t('New Ticket') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refTicketListTable"
        class="position-relative"
        :items="fetchTickets"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        small
        empty-text="No matching records found"
      >
        <template #cell(status)="data">
          <b-badge
            v-if="data.item.status==1"
            variant="success"
            class="badge-round"
          >
            {{ $t('Open') }}
          </b-badge>
          <b-badge
            v-else-if="data.item.status==2"
            variant="warning"
            class="badge-round"
          >
            {{ $t('Answered') }}
          </b-badge>
          <b-badge
            v-else-if="data.item.status==0"
            variant="danger"
            class="badge-round"
          >
            {{ $t('Closed') }}
          </b-badge>
        </template>
        <template #cell(created_at)="data">
          <span>{{ $format_datetime(data.item.created_at) }}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'support-messages', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('Show') }}</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('Entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalPayments"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, VBModal, BBadge, BTable, BDropdown, BDropdownItem, BPagination, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import {
  ref, onUnmounted, watch, computed,
} from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import supportStoreModule from './supportStoreModule'
import newTicket from './NewTicket.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    newTicket,
    vSelect,
    BButton,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      showCategoriyEditor: false,
      selectedBrand: null,
      editComponentMode: 'add',
      selectedId: 0,

    }
  },
  methods: {
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },

    openModal(mode, data = null) {
      this.editComponentMode = mode
      if (mode === 'edit' && data != null) {
        this.selectedBrand = data
      }
      this.showCategoriyEditor = true
    },
    brandDataChanged() {
      this.showCategoriyEditor = false
      this.$refs.refTicketListTable.refresh()
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-support'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, supportStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const toast = useToast()
    // eslint-disable-next-line no-unused-vars
    const isAddNewTicketSidebarActive = ref(false)

    const perPageOptions = [10, 25, 50, 100]
    const perPage = ref(10)
    const searchQuery = ref('')
    const totalPayments = ref(0)
    const refTicketListTable = ref(null)
    const currentPage = ref(1)
    const tableColumns = [
      { key: 'title', label: 'Title', sortable: false },
      { key: 'support_type_name', label: 'Ticket Type', sortable: false },
      { key: 'created_at', label: 'Created Date', sortable: false },
      { key: 'status', label: 'Status', sortable: false },
      { key: 'actions' },
    ]

    const refetchData = () => {
      refTicketListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery], () => {
      refetchData()
    })
    const dataMeta = computed(() => {
      const localItemsCount = refTicketListTable.value ? refTicketListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalPayments.value,
      }
    })
    const fetchTickets = (ctx, callback) => {
      store
        .dispatch('app-support/fetchTickets', {
          q: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
        })
        .then(response => {
          const { data } = response.data.data
          const { total } = response.data.meta
          callback(data)
          totalPayments.value = total
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching tickets list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    return {

      // Sidebar
      isAddNewTicketSidebarActive,
      tableColumns,
      // Filter
      perPageOptions,
      perPage,
      dataMeta,
      fetchTickets,
      searchQuery,
      totalPayments,
      currentPage,
      refetchData,
      // UI
      refTicketListTable,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
