<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewTicketSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetTicketData"
    @change="(val) => $emit('update:is-add-new-ticket-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('Add support ticket') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer

        ref="formTicket"
      >
        <status-alert
          :is-success="isSuccess"
          :error-data="errorData"
          :success-msg="$t('Tax and currency info updated')"
        />
        <!-- Form -->
        <b-form
          class="p-2"
        >
          <!-- Ticket Type-->
          <validation-provider
            #default="{ errors }"
            :name="$t('Ticket Type')"
            rules="required"
          >
            <b-form-group
              :label="$t('Ticket Type')"
              label-for="title"
            >
              <v-select
                v-model="ticketData.support_type"
                class="lg-input"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="ticketTypeOptions"
                :reduce="val => val.value"
                autocomplete="chrome-off"
                :clearable="false"
                :state="errors.length > 0 ? false:null"
                input-id="country_KDR"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Support Title -->
          <validation-provider
            #default="{ errors }"
            :name="$t('Support Title')"
            rules="required|min:2"
          >
            <b-form-group
              :label="$t('Support Title')"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="ticketData.title"
                autofocus
                :state="errors.length > 0 ? false:null"
                trim
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Message -->
          <validation-provider
            #default="{ errors }"
            :name="$t('Message')"
            rules="required|min:2"
          >
            <b-form-group
              :label="$t('Message')"
              label-for="message"
            >

              <b-form-textarea
                id="textarea-state"
                v-model="ticketData.message"

                placeholder="Enter ticket message"
                rows="3"
                :state="errors.length > 0 ? false:null"
              />
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="button"
              @click="saveData"
            >
              {{ $t('Save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import router from '@/router'
import vSelect from 'vue-select'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isAddNewTicketSidebarActive',
    event: 'update:is-add-new-ticket-sidebar-active',
  },
  props: {
    isAddNewTicketSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      ticketTypeOptions: [],
      isSuccess: false,
      errorData: null,
    }
  },
  created() {
    this.getTicketTypeOptions()
  },
  methods: {
    saveData() {
      this.$refs.formTicket.validate().then(success => {
        if (success) {
          store.dispatch('app-support/addTicket', this.ticketData)
            // eslint-disable-next-line no-unused-vars
            .then(response => {
              this.$emit('update:is-add-new-ticket-sidebar-active', false)
              router.replace({ name: 'support-messages', params: { id: response.data.data.id } })
              this.isSuccess = true
              this.errorData = null
              this.resetTicketData()
            })
            .catch(error => {
              if (error.response.status === 422) {
                this.errorData = error.response.data
              }
            })
        }
      })
    },

    getTicketTypeOptions() {
      store.dispatch('app-support/fetchSupportTypes', {})
        .then(response => {
          const arr = []
          response.data.data.forEach(val => arr.push({ label: val.title, value: val.id }))

          this.ticketTypeOptions = arr
        })
    },
  },
  setup() {
    const blankTicketData = {
      title: '',
      support_type: 1,
      message: '',

    }

    const ticketData = ref(JSON.parse(JSON.stringify(blankTicketData)))
    const resetTicketData = () => {
      ticketData.value = JSON.parse(JSON.stringify(blankTicketData))
    }

    resetTicketData()

    return {
      ticketData,
      resetTicketData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
